import { navigate } from "gatsby";
import React, { ReactElement, FC, useState } from "react";
import useAccount from "../hooks/useAccount";
import { PricingCard } from "./PricingCard";
import { SplashContainer } from "./SplashContainer";

type TWBPricingProps = {
    location: any;
    title?: string;
};

export const TWBPricing: FC<TWBPricingProps> = ({
    location,
    title,
}): ReactElement => {
    const [time, setTime] = useState<"Monthly" | "Annually">("Monthly");
    const [dismiss, setDismiss] = useState(location?.state?.dismiss ?? false);

    const activeButtonClassName = "bg-indigo-500 text-white";
    const buttonClassName = "";

    const [user, loading, error] = useAccount();

    const isAuthed = user !== undefined;

    const upgrade = (plan: string) => {
        navigate('/download');
        return;
        if (isAuthed) {
            navigate("/upgrade", { state: { plan } });
        } else {
            navigate("/signup", {
                state: { redirect: "/upgrade", plan },
            });
        }
    };

    const clear = () => {
        if (!isAuthed) {
            navigate("/signup", {
                state: { redirect: { to: "/download", from: "/download" } },
            });
        } else {
            setDismiss(true);
        }
    };

    return (
        <div>
            <section
                className="text-white body-font"
                style={{ background: "#222127" }}
            >
                <SplashContainer>
                    <div className="px-5 py-16 flex flex-col justify-center">
                        <div className="flex flex-col text-center w-full mb-20">
                            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">

                                {title ?? "Pricing"}
                            </h1>
                            <h1 className="sm:text-2xl text-2xl font-medium title-font mb-2 text-white">

                                {title ?? "During open beta, Streamlux Pro features are available to all users for free."}
                            </h1>
                            {/* <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                                whitever cardigan tote bag tumblr hexagon
                                brooklyn asymmetrical.
                            </p> */}
                            <div className="flex mx-auto border-2 border-primary rounded overflow-hidden mt-6">
                                <button
                                    className={`py-1 px-4 transition-all duration-150 outline-none border-0 ${
                                        time === "Monthly" ? "bg-primary" : ""
                                    } text-white focus:outline-none`}
                                    onClick={() => setTime("Monthly")}
                                >
                                    Monthly
                                </button>
                                <button
                                    className={`py-1 px-4 transition-all duration-150 border-0 ${
                                        time === "Annually" ? "bg-primary" : ""
                                    } focus:outline-none outline-none`}
                                    onClick={() => setTime("Annually")}
                                >
                                    Annually
                                </button>
                            </div>
                        </div>
                        <div
                            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:gap-4 justify-items-center w-full place-content-center self-center"
                            style={{ maxWidth: 1600 }}
                        >
                            <PricingCard
                                label="START"
                                key="Free"
                                price="Free"
                                buttonText="Download"
                                features={[
                                    "Unlocked color picker for 16 million colors",
                                    "Lux editor (1 row)",
                                    "3 devices",
                                    "5 chat commands",
                                ]}
                                onClick={() => navigate('/download')}
                                buttonProps={{ color: "secondary" }}
                            />
                            <PricingCard
                                label="BASIC"
                                key="BASIC"
                                price={
                                    time === "Monthly" ? "$4/mo" : "$29.99/yr"
                                }
                                buttonText="Get Basic"
                                features={[
                                    "Unlocked color picker for 16 million colors",
                                    "Lux editor (3 rows)",
                                    "6 devices",
                                    "Unlimited chat commands",
                                ]}
                                onClick={() => upgrade("basic")}
                            />
                            <PricingCard
                                label="PRO"
                                key="PRO"
                                price={
                                    time === "Monthly"
                                        ? "$7.99/mo"
                                        : "$49.99/yr"
                                }
                                buttonText="Get Pro"
                                features={[
                                    "Unlocked color picker for 16 million colors",
                                    "Lux Editor (6 rows)",
                                    "Unlimited devices",
                                    "Unlimited chat commands",
                                ]}
                                onClick={() => upgrade("pro")}
                            />
                        </div>
                    </div>
                </SplashContainer>
            </section>
        </div>
    );
};
