import React, { CSSProperties, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { Button } from "luxd";
import useBreakpoint from "use-breakpoint";
import { WhyStreamlux } from "../components/WhyStreamlux";
import { FaDownload } from "react-icons/fa";

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1024 };

const Hero = ({ contentModuleId }: { contentModuleId: string }) => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulLayoutHero {
                edges {
                    node {
                        id
                        heading
                        subheading
                        description {
                            description
                        }
                        ctaText
                        ctaUrl
                        mobileImage {
                            fluid(quality: 100) {
                                ...GatsbyContentfulFluid
                            }
                            file {
                                url
                            }
                        }
                        tabletImage {
                            fluid(quality: 100) {
                                ...GatsbyContentfulFluid
                            }
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const [_, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");

    const content = data.allContentfulLayoutHero.edges.find(
        edge => edge.node.id === contentModuleId
    );

    const mobileStyle: CSSProperties = {
        minHeight: "calc(100vh - 136px)",
    };

    const tabletStyle: CSSProperties = {
        minHeight: "calc(100vh - 112px)",
    };

    const breakpointToBgMap = {
        mobile: mobileStyle,
        tablet: tabletStyle,
        desktop: {},
    };

    const leftSide = (
        <img
            src={require("../assets/images/leftSide.svg")}
            alt="Decorative shelf with plant and headphone stand with headphones."
        ></img>
    );
    const rightSide = (
        <img
            src={require("../assets/images/rightSide.svg")}
            alt="Decorative hanging lamp."
        ></img>
    );
    const bottomMobile = (
        <img
            src={require("../assets/images/Mobile_landing_splash.svg")}
            className="mx-0"
            alt="Streaming setup, with speakers, RGB lights, and headphones."
        ></img>
    );
    const bottomTablet = (
        <img
            src={require("../assets/images/Mobile_landing_splash.svg")}
            alt="Streaming setup, with speakers, RGB lights, and headphones."
            // className="mx-24"
        ></img>
    );
    const bottomNormal = (
        <img
            src={require("../assets/images/Bottom.svg")}
            alt="Monitor with speakers, a mouse, and RGB lights."
        ></img>
    );

    const Bottom = () => {
        if (height < 500) {
            return <></>;
        }
        if (breakpoint === "mobile") {
            return bottomMobile;
        }
        if (breakpoint === "tablet") {
            return bottomTablet;
        }
        return bottomNormal;
    };

    return (
        <div style={{ background: "#222127" }}>
            <div>
                <section
                    className="hero flex flex-col h-screen w-full items-center flex-grow"
                    style={{
                        background: "#1F1F24",
                        minHeight: "calc(100vh - 112px)",
                        height: "max-content",
                        // ...breakpointToBgMap[breakpoint],
                    }}
                >
                    <div
                        className="flex flex-col w-full max-h-full h-full justify-start"
                        style={{ minHeight: "calc(100vh - 112px)" }}
                    >
                        <div className="flex flex-row w-full h-full flex-grow align-top">
                            {breakpoint === "desktop" && (
                                <div className="">{leftSide}</div>
                            )}
                            <div className="flex flex-col items-center justify-start flex-grow">
                                <div
                                    className="flex flex-col items-center justify-top space-y-4 xl:space-y-8 h-full"
                                    style={{ marginTop: "10vh" }}
                                >
                                    <div className="flex flex-col space-y-6 xl:space-y-8">
                                        <h1 className="text-4xl sm:text-4xl md:text-5xl lg:text-5xl xl:text-7xl font-changa whitespace-nowrap text-center">
                                            Level up your stream
                                        </h1>
                                        <h2 className="text-xl sm:text-2xl sm:max-w-3xl md:text-xl xl:text-2xl max-w-3xl self-center text-center font-body">
                                            Connect your lights to events from
                                            your stream, like new followers,
                                            subscribers, chat commands, and
                                            more.
                                        </h2>
                                    </div>
                                    <div className="grid grid-cols-1 lg:grid-cols-2 px-8 w-full gap-4 justify-items-stretch self-center">
                                        <Button
                                            color="secondary"
                                            onClick={() =>
                                                navigate("/download")
                                            }
                                            icon={<FaDownload />}
                                        >
                                            Download
                                        </Button>
                                        <Button
                                            color="primary"
                                            onClick={() =>
                                                window.open(
                                                    "https://streamlux.com/app"
                                                )
                                            }
                                        >
                                            Start Now
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            {breakpoint === "desktop" && <div>{rightSide}</div>}
                        </div>
                        <div className="flex flex-col justify-end">
                            {<Bottom />}
                        </div>
                    </div>
                </section>
                <WhyStreamlux />
            </div>
        </div>
    );
};

Hero.propTypes = {
    contentModuleId: PropTypes.string.isRequired,
};

export default Hero;
