import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import React, { FC, ReactElement } from "react";

type FeatureWithIcon = {
    node: {
        featureName: string;
        icon: {
            file: {
                url: string;
            };
        };
    };
};

interface IFeatureIconsProps {
    features: string[];
    activeFeature: string;
}

export const FeatureBar: FC<IFeatureIconsProps> = ({
    features,
    activeFeature,
}): ReactElement => {
    const hash = useLocation().hash;

    return (
        <div
            className="flex flex-row justify-evenly py-8 md:px-16 h-24 md:h-32 lg:h-48 items-center"
            style={{ background: "#2C2C31" }}
            id={activeFeature}
        >
            {features.map((feature: string) => {
                const isActive = activeFeature === feature;

                return (
                    <div className="flex flex-col space-y-4 md:justify-center" key={feature}>
                        <Link to={`/#${feature}`}>
                            <p
                                className={`text-center font-semibold md:text-2xl lg:text-3xl xl:text-3xl 2xl:text-4xl font-hind  uppercase transition-all duration-150 ${
                                    isActive ? "text-primary" : "text-offWhite"
                                }`}
                            >
                                {feature}
                            </p>
                        </Link>
                    </div>
                );
            })}
        </div>
    );
};
