import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import parse from "html-react-parser";
import { IoMdArrowForward } from "react-icons/io";

const SupportedDevices = ({ contentModuleId }) => {
    const data = useStaticQuery(graphql`
        {
            allContentfulSupportedDevice {
                nodes {
                    childContentfulSupportedDeviceDescriptionRichTextNode {
                        childContentfulRichText {
                            html
                        }
                    }
                    name
                }
            }
            allContentfulLayoutSupportedDevices {
                edges {
                    node {
                        id
                        buttonLink
                        buttonText
                        name
                        childContentfulLayoutSupportedDevicesDescriptionRichTextNode {
                            childContentfulRichText {
                                html
                            }
                        }
                        title
                    }
                }
            }
        }
    `);

    const devices = data.allContentfulSupportedDevice.nodes?.map(device => {
        return (
            <div className="text-2xl 2xl:text-3xl whitespace-nowrap font-body text-center" key={device.name}>
                {device.name}
            </div>
        );
    });

    const pre = <pre>{JSON.stringify(data, null, 4)}</pre>;

    const node = data.allContentfulLayoutSupportedDevices.edges[0].node;

    return (
        <>
            <div
                className="pb-24 py-16 sm:px-16 justify-center items-center flex flex-col text-offWhite"
                style={{ background: "#222127" }}
            >
                <h2 className="font-bold font-body text-2xl py-16 text-center">
                    Supporting {devices.length} device brands and apps with more
                    coming every update.
                </h2>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-16 sm:gap-32 py-16">
                    {devices}
                </div>
                {/* <Link
                    to={node.buttonLink}
                    className="self-center flex flex-row items-center space-x-2 text-xl"
                >
                    <p>See full list</p>
                    <IoMdArrowForward />
                </Link> */}
            </div>
        </>
    );
};

SupportedDevices.propTypes = {
    contentModuleId: PropTypes.string.isRequired,
};

export default SupportedDevices;
