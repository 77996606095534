import React, { ReactElement } from "react";

interface IInfoPanelProps {
}

export const Card = (
    props: React.PropsWithChildren<IInfoPanelProps>
): ReactElement => {
    const { children } = props;

    return (
        <div className="flex flex-col">
            <div className="bg-dark shadow-md rounded-lg text-offWhite text-xl p-4 sm:px-16 sm:py-8">
                {children}
            </div>
        </div>
    );
};
