import React, { FC, ReactElement } from "react";
import Hero from "./Hero";
import FeatureDisplay from "./Features";
import PageNotFound from "./404Page";
import SupportedDevices from "./devices";
import LoginForm from "./LoginForm";
import AccountSettings from "./account";
import LandingPageSections from "./LandingPageSections";
import Preview from "./Preview";
import Premium from "./Premium";

const components = {
    Hero,
    Features: FeatureDisplay,
    SupportedDevices,
    LoginForm,
    Account: AccountSettings,
    PageNotFound,
    LandingPageSections,
    Preview,
    Pricing: Premium,
};

type SectionProps = {
    contentModuleId: string;
    type: keyof typeof components;
};

const Section: FC<SectionProps> = ({ contentModuleId, type }): ReactElement => {

    const component: keyof typeof components = (type.split(
        "Layout"
    )[1] as unknown) as keyof typeof components;

    if (typeof components[component] === "undefined") {
        return "";
    }

    return React.createElement(components[component], {
        contentModuleId,
    });
};

export default Section;
