import { Button, ButtonProps } from "luxd";
import React, { ReactElement, FC, ReactNode } from "react";

type PricingCardProps = {
    label: string;
    price: string;
    features: (string | ReactNode)[];
    buttonText: string;
    special?: string;
    buttonProps?: ButtonProps;
    onClick?: () => void;
};

const BulletPoint: FC = () => (
    <span className="w-2.5 h-2.5 mr-2 inline-flex items-center justify-center bg-primary text-primary rounded-full flex-shrink-0">
        <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.5"
            className="w-2 h-2"
            viewBox="0 0 24 24"
        >
            <path d="M20 6L9 17l-5-5"></path>
        </svg>
    </span>
);

export const PricingCard: FC<PricingCardProps> = ({
    label,
    price,
    features,
    special,
    buttonProps,
    buttonText,
    onClick,
}): ReactElement => {
    return (
        <div
            className="p-4 w-full rounded-xl max-w-sm"
            style={{ minHeight: 450 }}
        >
            <div className="h-full p-6 rounded-lg bg-darkGray flex flex-col relative overflow-hidden">
                {special && (
                    <span className="bg-indigo-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
                        {special}
                    </span>
                )}
                <h2 className="text-sm tracking-widest text-white title-font mb-1 font-medium">
                    {label}
                </h2>
                <h1 className="text-5xl text-white pb-4 mb-4 border-b-2 border-primary leading-none">
                    {price}
                </h1>
                {features.map((feature, index) => {
                    return (
                        <p className="flex items-center text-white mb-2" key={index + 'featureBullet'}>
                            <BulletPoint />
                            {feature}
                        </p>
                    );
                })}
                <Button
                    {...buttonProps}
                    onClick={onClick}
                    className="flex items-center mt-auto border-0 py-2 px-4 w-full focus:outline-none rounded"
                >
                    {buttonText}
                </Button>
                {/* <p className="text-xs text-white mt-3">
                    Literally you probably haven't heard of them jean shorts.
                </p> */}
            </div>
        </div>
    );
};
