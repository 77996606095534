import React, { FC } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Feature } from "../components/Feature";

const FeatureDisplay: FC = () => {
    const data = useStaticQuery(graphql`
        {
            allContentfulLayoutFeatures {
                nodes {
                    features {
                        id
                        title
                        featureName
                        description {
                            id
                            description
                        }
                        image {
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const features = data.allContentfulLayoutFeatures.nodes[0].features;

    return (
        <>
            <section
                className="py-32 space-y-24"
                style={{ background: "#222127" }}
            >
                {features.map((content: any, index: number) => {
                    let imageOnLeft = true;

                    let color = "#FFFFFF";
                    let className = "";
                    if (index % 2 === 0) {
                        color = "#F5F5F5";
                        imageOnLeft = false;
                        className = "justify-evenly";
                    }
                    return (
                        <Feature
                            index={index}
                            imgSrc={content.image.file.url}
                            title={content.title}
                            key={content.title}
                            subtitle={
                                JSON.parse(content.description.description).content[0].content[0].value
                            }
                            section={content.featureName}
                        />
                    );
                })}
            </section>
        </>
    );
};

export default FeatureDisplay;
