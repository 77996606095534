import React from "react";
import useAccount from "../hooks/useAccount";
import { AccountController } from "../components/account/AccountController";
import { Redirect } from "@reach/router";

const AccountSettings = () => {
    const [user, loading, error] = useAccount();

    return (
        <div
            className="w-full flex-grow h-auto bg-darkGray py-16 space-y-8 text-offWhite"
            style={{ paddingLeft: "10vw", paddingRight: "10vw" }}
        >
            {!user ? (
                loading ? (
                    <h1>Loading...</h1>
                ) : (
                    <Redirect noThrow to="/" />
                )
            ) : (
                <AccountController user={user} />
            )}
        </div>
    );
};

export default AccountSettings;
