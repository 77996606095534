import React, { ReactElement, FC, ReactNode } from "react";

type FeatureProps = {
    imgSrc: string;
    title: string;
    subtitle: ReactNode | string;
    section: string;
    index: number;
};

const colors = ["#35C9D9", "#C41D7F", "#FEFF5E", "#5747FF"];

export const Feature: FC<FeatureProps> = ({
    imgSrc,
    title,
    subtitle,
    index,
    section,
}): ReactElement => {
    const titleWords = title.split(" ");
    const firstWord = titleWords.shift();
    const odd = index % 2 === 1;

    return (
        <div
            className="flex flex-col px-16 md:px-24 xl:px-64 pb-0 sm:pb-32 2xl:px-96"
            style={{ backgroundColor: "#222127" }}
        >
            <div>
                <div className="grid grid-cols-1 sm:grid-cols-5 w-full h-full justify-between space-y-8 sm:space-y-0 sm:space-x-8">
                    <div
                        className="col-span-3 flex flex-col text-offWhite space-y-12"
                        data-sal={odd ? "slide-right" : "slide-up"}
                        data-sal-delay="150"
                        data-sal-easing="ease-out-back"
                        data-sal-once
                    >
                        <h2 className="text-2xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-4xl 2xl:text-6xl font-extrabold">
                            <span
                                style={{ color: colors[index % colors.length] }}
                            >
                                {firstWord + " "}
                            </span>
                            {titleWords.join(" ")}
                        </h2>
                        <div className="text-xl lg:text-xl xl:text-2xl 2xl:text-2xl font-body">
                            {subtitle}
                        </div>
                    </div>
                    <div className="flex-grow w-full col-span-2 items-center flex flex-col justify-center">
                        <img
                            src={imgSrc}
                            className="rounded-lg w-5/6 h-full"
                            alt="Feature graphic"
                            data-sal={odd ? "slide-up" : "slide-left"}
                            data-sal-delay="150"
                            data-sal-easing="ease-out-back"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
