import { useStaticQuery, graphql } from "gatsby";
import React, { ReactElement, FC, useState } from "react";
import useAppwrite from "../../hooks/useAppwrite";
import { User } from "../../types";
import { gotoRoot } from "../../utils/navigation";
import { BillingDetails } from "./billingDetails";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { Card } from "./infoPanel";
import { SubscriptionDetails } from "./subscriptionDetails";
import {
    MdEmail,
    MdReportProblem,
    MdSend,
    MdVerifiedUser,
} from "react-icons/md";
import Popup from "reactjs-popup";

type AccountControllerProps = {
    user: User;
};

export const AccountController: FC<AccountControllerProps> = ({
    user,
}): ReactElement => {
    const data = useStaticQuery(graphql`
        {
            allContentfulLayoutAccount {
                edges {
                    node {
                        id
                        backgroundImage {
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const [page, setPage] = useState("Personal Information");
    const [plan, setPlan] = useState({} as any);

    const appwrite = useAppwrite();

    const logout = () => {
        const logoutReq = appwrite.account.deleteSession("current");
        logoutReq.then(() => {
            gotoRoot(true);
        });
    };

    const content = data.allContentfulLayoutAccount.edges[0].node;

    const Subscription = (
        <div className="space-y-8">
            <h1 className="text-3xl font-semibold">Plan information</h1>
            <Card>
                <SubscriptionDetails
                    subscription={user.prefs.subcriptionDetails}
                    plan={plan}
                />
            </Card>
            {plan && (
                <div>
                    <h1 className="text-3xl font-semibold">Billing</h1>
                    <Card>
                        <BillingDetails
                            subscription={user.prefs.subcriptionDetails}
                            plan={plan}
                        />
                    </Card>
                </div>
            )}
        </div>
    );

    const changePlan = <div>change plan</div>;

    const regDate = new Date();
    regDate.setMilliseconds(user.registration);

    const General = (
        <div className="space-y-8">
            <h1 className="text-3xl font-semibold">General</h1>
            <Card>
                <div className="flex flex-col space-y-8">
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-col space-y-2 font-display">
                            <div className="flex flex-row items-center space-x-2">
                                <h2 className="text-xl">Email address</h2>

                                {user.emailVerification && (
                                    <Popup
                                        on={["hover"]}
                                        trigger={
                                            <div className="flex flex-row items-center justify-center">
                                                <MdVerifiedUser className="text-primary" />
                                            </div>
                                        }
                                        position="top center"
                                        closeOnDocumentClick
                                    >
                                        <span className="text-center text-sm">
                                            Verified email
                                        </span>
                                    </Popup>
                                )}
                            </div>
                            <p className="text-2xl font-bold">{user?.email}</p>
                        </div>
                        <div className="flex flex-col space-y-2 font-display">
                            <h2 className="text-xl">User since</h2>
                            <p className="text-2xl font-bold">
                                {regDate.toDateString()}
                            </p>
                        </div>
                        <div className="flex flex-col justify-center">
                            <div className="flex flex-row space-x-4">
                                <button
                                    onClick={logout}
                                    className="btn btn--danger"
                                >
                                    Logout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );

    const pages = [
        {
            name: "Personal Information",
            view: General,
        },
        // {
        //     name: "Subscription and Billing",
        //     view: Subscription,
        // },
        {
            name: "Password and Security",
            view: (
                <div className="space-y-8">
                    <h1 className="text-4xl font-semibold">Change password</h1>
                    <Card>
                        <ChangePasswordForm user={user} />
                    </Card>
                    <h1 className="text-4xl font-semibold">Danger zone</h1>
                    <Card>Delete account</Card>
                </div>
            ),
        },
    ];

    return (
        <div className="flex flex-row space-x-8">
            <div className="w-1/4 px-4">
                <div className="flex flex-col space-y-4">
                    <h1 className="text-4xl font-changa">Account Settings</h1>
                    <ul className="settingsMenu space-y-4">
                        {pages.map(p => (
                            <li
                                className={`font-display text-2xl px-4 py-2 rounded-lg  transition duration-100 ${
                                    p.name === page
                                        ? "bg-tertiary border-primary border-2 shadow-none"
                                        : "bg-dark border-2 border-transparent shadow-lg"
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => setPage(p.name)}
                            >
                                {p.name}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="w-3/4">
                {pages.find(p => p.name === page)?.view}
            </div>
        </div>
    );
};
