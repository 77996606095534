import { Button, PasswordInput } from "luxd";
import React, { ReactElement, FC, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toast";
import { ApiCall } from "../../appwrite/appwrite";
import useAppwrite from "../../hooks/useAppwrite";
import { MixpanelContext } from "../../metrics";
import { User } from "../../types";
import { FormStatus } from "../forms/forms";
import { SignUpFormInputs } from "../forms/signupForm";

type ChangePasswordFormProps = {
    user: User;
};

export const ChangePasswordForm: FC<ChangePasswordFormProps> = ({
    user,
}): ReactElement => {
    const appwrite = useAppwrite();

    const mixpanel = useContext(MixpanelContext);

    const [status, setStatus] = useState<FormStatus | undefined>();

    const { register, handleSubmit, errors, getValues } = useForm<
        SignUpFormInputs
    >();

    const onSubmit = (data: SignUpFormInputs) => {
        setStatus("loading");
        ApiCall<User>(
            appwrite.account.updatePassword(
                data.password,
                data.confirmPassword
            ),
            userResp => {
                toast.success("Password changed.");
            },
            errResp => {
                toast.error("Error: " + errResp.message);
            }
        );
        setStatus("success");
    };

    return (
        <div className="">
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col space-y-4 max-w-sm"
            >
                <PasswordInput
                    name="password"
                    label="Password"
                    id="password"
                    ref={register({
                        required: true,
                        minLength: {
                            value: 8,
                            message: "Password must be at least 8 characters",
                        },
                    })}
                    error={errors.password?.message}
                />
                <PasswordInput
                    name="confirmPassword"
                    label="Confirm password"
                    id="confirmPassword"
                    ref={register({
                        required: true,
                        validate: value =>
                            value === getValues().password ||
                            "Does not match password",
                    })}
                    error={errors.confirmPassword?.message}
                />
                <div className="flex flex-col pt-4">
                    <Button type="submit" loading={status === "loading"}>
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    );
};
