import React, { ReactElement, FC } from "react";
import { SplashContainer } from "./SplashContainer";
import { Button } from "luxd";
import { FaDiscord, FaDownload } from "react-icons/fa";
import { joinDiscord } from "../utils/navigation";
import { navigate } from "gatsby";

type WhyStreamluxProps = {};

export const WhyStreamlux: FC<WhyStreamluxProps> = ({}): ReactElement => {
    return (
        <div
            className="w-full text-offWhite hidden lg:flex flex-col justify-center items-center px-8"
            id="why"
            style={{
                minHeight: "40vw",
                maxHeight: 600,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${require("../assets/images/level_up_large.svg")})`,
            }}
        >
            <div className='grid grid-cols-2 w-full'>
                <div />
                <div className="flex flex-col space-y-8 py-2 max-w-3xl">
                    <h3 className="uppercase lg:text-xl xl:text-2xl">
                        Why streamlux?
                    </h3>
                    <h2 className="font-display font-bold lg:text-2xl xl:text-4xl 2xl:text-6xl text-offWhite">
                        Level up up your stream
                    </h2>
                    <div className="text-offWhite lg:text-base font-body xl:text-2xl font-light max-w-3xl space-y-6">
                        <p>
                            Streamlux is a{" "}
                            <strong className="font-extrabold">
                                desktop application
                            </strong>{" "}
                            for{" "}
                            <strong className="font-extrabold">Twitch</strong>{" "}
                            streamers that can help you create custom light
                            effects and connect them to events like new
                            followers, new subscribers, chat events, and more.
                        </p>
                        <p>
                            With Streamlux, you can{" "}
                            <strong className="font-extrabold">
                                engage your users
                            </strong>{" "}
                            by creating a visual experience with your lights
                            that they can control by supporting you!
                        </p>
                    </div>
                    <div className="flex flex-row space-x-4">
                        <Button
                            color="secondary"
                            className="w-full"
                            icon={<FaDownload />}
                            onClick={() => navigate("/download")}
                        >
                            Download
                        </Button>
                        <Button
                            color="secondary"
                            className="w-full"
                            icon={<FaDiscord />}
                            onClick={() => joinDiscord()}
                        >
                            Join our Discord
                        </Button>
                    </div>
                </div>
            </div>
            <div className="lg:hidden">
                <SplashContainer>
                    <div style={{ height: "max-content" }}>
                        <div className="flex flex-col space-y-8 p-8">
                            <h3 className="uppercase text-lg xl:text-2xl">
                                Why streamlux?
                            </h3>
                            <h2 className="font-display font-bold text-2xl lg:text-2xl xl:text-4xl 2xl:text-5xl text-offWhite">
                                Level up up your stream
                            </h2>
                            <div className="text-offWhite text-lg xl:text-xl font-body max-w-2xl space-y-6">
                                <p>
                                    Streamlux is a{" "}
                                    <strong className="font-extrabold">
                                        desktop application
                                    </strong>{" "}
                                    for{" "}
                                    <strong className="font-extrabold">
                                        Twitch
                                    </strong>{" "}
                                    streamers that can help you create custom
                                    light effects and connect them to events
                                    like new followers, new subscribers, chat
                                    events, and more.
                                </p>
                                <p>
                                    With Streamlux, you can{" "}
                                    <strong className="font-extrabold">
                                        engage your users
                                    </strong>{" "}
                                    by creating a visual experience with your
                                    lights that they can control by supporting
                                    you!
                                </p>
                            </div>
                        </div>
                    </div>
                </SplashContainer>
            </div>
        </div>
    );
};
