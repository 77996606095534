import { graphql, useStaticQuery } from "gatsby";
import React, { ReactElement, FC } from "react";
import { FeatureBar } from "../components/FeatureBar";
import Section from "./section";

type LandingPageSectionsProps = {};

type Section = {
    __typename: string;
    id: string;
    title: string;
};

function getSectionName(type: string): string {
    return type.split("Layout")[1];
}

const LandingPageSections: FC<LandingPageSectionsProps> = ({}): ReactElement => {
    const data = useStaticQuery(graphql`
        {
            allContentfulLayoutLandingPageSections {
                edges {
                    node {
                        id
                        sections {
                            ... on ContentfulLayoutFeatures {
                                id
                                title
                            }
                            ... on ContentfulLayoutPreview {
                                id
                                title
                            }
                            ... on ContentfulLayoutSupportedDevices {
                                id
                                title
                            }
                        }
                        contentful_id
                    }
                }
            }
        }
    `);

    const sections: Section[] =
        data.allContentfulLayoutLandingPageSections.edges[0].node.sections;

    const sectionNames = sections.map(section =>
        section.title
    );

    return (
        <div>
            {sections &&
                sections.length > 0 &&
                sections.map((section: Section) => (
                    <div key={section.id}>
                        <FeatureBar
                            activeFeature={section.title}
                            features={sectionNames}
                        />
                        <Section
                            contentModuleId={section.id}
                            type={section.__typename}
                            key={section.id}
                        />
                    </div>
                ))}
        </div>
    );
};

export default LandingPageSections;
