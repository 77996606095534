import { graphql, useStaticQuery } from "gatsby";
import React, { ReactElement, FC } from "react";
import parse from "html-react-parser";
import { ScreenshotFrame } from "../components/ScreenshotFrame";

// type PreviewProps = {
//     title: string;
//     subtitle: string;
//     images: string[];
// };

export type PreviewItem = {
    image: {
        file: {
            url: string;
        };
    };
    heading: string;
    subheading: {
        childrenContentfulRichText: { html: string }[];
    };
};

const Preview: FC = (): ReactElement => {
    const data = useStaticQuery(graphql`
        {
            allContentfulLayoutPreview {
                nodes {
                    items {
                        image {
                            file {
                                url
                            }
                        }
                        heading
                        subheading {
                            childrenContentfulRichText {
                                html
                            }
                        }
                    }
                }
            }
        }
    `);

    const content: PreviewItem[] =
        data.allContentfulLayoutPreview.nodes[0].items;

    const styleMap = {
        mobile: {
            width: "100%",
            height: "auto",
        },
        tablet: {
            width: "100%",
            height: "auto",
        },
        desktop: {
            width: "1200px",
            height: "800px",
        },
    };

    return (
        <div
            className="flex flex-col sm:flex-col md:flex-col lg:flex-col py-16 sm:py-16 sm:px-16 px-8 space-y-8 sm:space-y-0 md:space-y-8"
            style={{ background: "#222127" }}
        >
            <div className="flex flex-col items-center">
                <div className="sm:my-auto sm:px-16 space-y-2 lg:space-y-4 xl:space-y-16 text-offWhite">
                    <div className="flex flex-col space-y-4 lg:space-y-8 pb-4">
                        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl font-body font-bold">
                            {content[0]?.heading}
                        </h2>
                        <div className="text-xl sm:text-xl font-body">
                            {parse(
                                content[0]?.subheading
                                    .childrenContentfulRichText[0].html
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="flex flex-col items-center mx-auto my-16"
                style={{ maxWidth: "70vw" }}
            >
                <ScreenshotFrame url={content[0]?.image.file.url} />
            </div>
        </div>
    );
};

export default Preview;
